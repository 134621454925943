import React, { useEffect, useState } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { GithubLogo, DiscordLogo } from "@phosphor-icons/react";

import Button from '../Button';
import { formatCurrency } from '../../utils/number';

import Icon, { Euler, Arbitrum } from '../Icon';
import roboImg from '../../assets/robo-labs.png';

import {
	Actions,
	Wrapper,
	Intro,
	Stats,
	About,
	Features,
	Partners,
} from './Content.styled';

const inception = new Date('Feb 12, 2025');

const API_ENDPOINT = "https://api.reservoir.fi/v1/pairs";

const Content = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
	const date = formatDistanceStrict(inception, new Date(), { unit: 'day' });

	const [vol, setVol] = useState('0');
	const [tvl, setTvl] = useState(0);
	const [pairCount, setPairCount] = useState(0)
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await fetch(API_ENDPOINT);
				const data = await response.json();
				const pairs = Object.values(data);
				setPairCount(pairs.length);
				const totalValueLocked = pairs.reduce((acc, pair) => {
					const token0Value = pair.token0Reserve * pair.token0.usdPrice;
					const token1Value = pair.token1Reserve * pair.token1.usdPrice;
					return acc + token0Value + token1Value;
				}, 0);
				setTvl(totalValueLocked);
				const totalVolume = pairs.reduce((acc, pair) => {
					const token0Volume = pair.token0Volume * pair.token0.usdPrice;
					const token1Volume = pair.token1Volume * pair.token1.usdPrice;
					return acc + token0Volume + token1Volume;
				}, 0);
				setVol(totalVolume.toFixed(2));
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return (
		<Wrapper>
			<Intro>
				<h2>Reservoir Finance</h2>
				<p>Bringing capital efficiency to DeFi through transparency, responsible credit usage, and superfluid collateral.</p>
				<Actions>
					{isTabletOrMobile ? (
						<Button alt small>Launch App</Button>
					) : (
						<div className='social'>
							<a
								href="https://x.com/ReservoirFi"
								target="_blank"
								rel="noreferrer"
							>
								<svg width="24" height="24" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 87.7831H306.615L611.412 515.685L658.88 583.579L1055.08 1139.58H892.476L569.165 687.854V687.828Z" fill="#0f3846"/>
								</svg>
							</a>
							<a href="https://github.com/orgs/reservoir-labs/" target="_blank" rel="noreferrer">
								<GithubLogo size={24} weight="fill" color="#0f3846" />
							</a>
							<a href="https://discord.gg/SZjwsPT7CB" target="_blank" rel="noreferrer">
								<DiscordLogo size={24} weight="fill" color="#0f3846" />
							</a>
						</div>
					)}
				</Actions>
			</Intro>

			{loading ? null : (
				<Stats>
					<div>
						<span>{date}</span>
						<p>Since the inception of Reservoir</p>
					</div>
					<div>
						<span>{formatCurrency(tvl)}</span>
						<p>Total Liquidity</p>
					</div>
					<div>
						<span>{formatCurrency(vol)}</span>
						<p>Total Volume (24h)</p>
					</div>
					<div>
						<span>{pairCount}</span>
						<p>Total Pairs </p>
					</div>
				</Stats>
			)}

			<About>
				<div>
					<span>Simply Efficient DeFi.</span>
					<p>Reservoir Finance is dedicated to developing groundbreaking solutions that optimize capital efficiency. In order to achieve this goal, we foster a culture of rigorous risk assessment and open discussion. Unlike many existing decentralized finance (DeFi) platforms that heavily depend on centralized and opaque entities, our aim is to minimize lack of clarity, risk, and security. By doing so, we seek to enhance DeFi's product offerings and construct a more transparent and inclusive cryptocurrency ecosystem.</p>
				</div>
				<div>
					<Icon name='logo-outlined' />
				</div>
			</About>

			<Features>
				<div>
					<span>Manipulation Resistant Oracles</span>
					<p>Reservoir Finance has built in, manipulation resistant oracles for live accurate pricing and near instant data recollection.</p>
				</div>
				<div>
					<span>Multi-curve AMM</span>
					<p>A generic factory & AMM toolbox for deploying various passive AMM curves. Currently, x*y=k & StableSwap curves are implemented & live.</p>
				</div>
				<div>
					<span>Asset Management</span>
					<p>Reservoir Finance’s asset manager combines the power of idle tokens with the lending capabilities of money markets and yield protocols, providing additional “Yield while you LP”.</p>
				</div>
				<div>
					<span>DeFi Protocol Risk Pricing</span>
					<p>Comprehensive research on DeFi protocol risks & pricing of their annualized risk premium.</p>
					<p>Coming soon to a platform near you!</p>
				</div>
			</Features>

			<Partners>
				<div>
					<img src={roboImg} alt='partners' />
				</div>
				<div>
					<Euler />
				</div>
				<div>
					<Arbitrum />
				</div>
			</Partners>
		</Wrapper>
	);
};

export default Content;
