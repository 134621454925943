import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700&display=swap');

	*, *:before, *:after {
		box-sizing: border-box;
	}
	
	html {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html, body, #root {
		height: 100%;
	}

	body {
		box-sizing: border-box;
		font-family: 'Ubuntu Mono', monospace;
		background-color: #f8e0bb;
		margin: 0;
		line-height: 1.5;
	}

	h2 {
		font-family: 'Lato', sans-serif;
		font-weight: 700;
	}
	
	h3 {
		color: #0f3846;	
		font-weight: 700;
	}

	a {
		text-decoration: none;
    transition: color 0.2s ease;

		&:hover {
      color: #0f3846;
    }
	}
`;

export default GlobalStyle;
