import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.a`
    color: #FFFFFF;
    background: #0f3846;
    display: inline-block;
    padding: 12px;
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    transition: all 0.2s ease;
    cursor: pointer;
    text-decoration: none;

    ${({ small }) => small && css`
        padding: 8px;
        font-size: 0.8rem;
    `};

    ${({ alt }) => alt && css`
        background: #be7a58;
    `};

    &:hover {
        background: #be7a58;
        color: #fff;
        text-decoration: none;
    }
`;

const Button = ({ alt, small, children }) => {
    return (
        <StyledButton
            href="https://app.reservoir.fi"
            target="_blank"
            rel="noopener noreferrer"
            alt={alt}
            small={small}
        >
            {children}
        </StyledButton>
    );
};

export default Button;
